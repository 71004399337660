@import '~antd/dist/antd.css';

body {
  overflow: hidden;
  height: 100vh;
}

#root {
  height: 100%;
}

.login-form {
  max-width: 300px;
  margin: 32px auto;
}

.login-form-button {
  width: 100%;
}

.filters-wrapper {
  height: 100vh;
  margin: 12px;
}

.input-wrapper .ant-select,
.input-wrapper .ant-input
{
  width: 100%;
}

.input-wrapper {
  margin-bottom: 16px;
}

.input-wrapper label {
  display: block;
}

.ant-table-placeholder {
  height: 100vh;
}